/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

const HeaderShow = (props) => {
  const { title = '', copy = '' } = props;
  const [showHeaders, setShowHeaders] = useState(false);

  const mount = () => {
    setTimeout(() => {
      setShowHeaders(true);
    }, 100);
  };

  useEffect(() => {
    mount();
  }, []);
  return (
    <div className="section-overlay" style={{ backgroundColor: 'unset', pointerEvents: 'none' }}>
      {title.length > 0 ? <h2 style={showHeaders ? { opacity: 1, left: 50 } : { opacity: 0, left: -200 }}>{title}</h2> : <></>}
      {copy.length > 0 ? (
        <div className={`section-copy ${showHeaders ? 'show-copy' : ''}`} style={showHeaders ? { opacity: 1, left: 20 } : { opacity: 0, left: -200 }}>
          <div className="info-icon" />
          {copy}
        </div>
      ) : <></>}
    </div>
  );
};

export default HeaderShow;
