/* eslint-disable consistent-return */
import React, { createContext, useReducer } from 'react';

type stateType = {
  currentPath: string
  userSessionData: Object
}

type actionType = {
  type: {
    name: string,
    data: any
  }
}

const initialState = {
  currentPath: '/',
  userSessionData: {},
};

const store = createContext(initialState);
const { Provider }:any = store;

const StateProvider = ({ children } : any) => {
  const [state, dispatch] = useReducer<any>((currentState: stateType, action: actionType) => {
    const { type } = action;
    if (!type) return;

    const { name, data } = type;
    if (!name || !data) return;

    switch (name) {
      case 'UPDATE_CURRENT_PATH':
        return {
          ...currentState,
          currentPath: data,
        };

      case 'UPDATE_USER_SESSION_DATA':
        return {
          ...currentState,
          userSessionData: data,
        };

      case 'LOGOUT_USER_SESSION':
        return {
          ...currentState,
          userSessionData: {},
        };

      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
