/* eslint-disable prefer-template */
/* eslint-disable object-shorthand */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable object-curly-newline */
// @ts-nocheck

import React, { useContext, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from 'axios';

import {
  VideoComponent,
} from '../../components';

import './app-container.scss';
import teaserVideo from '../../assets/v2/main.mp4';
import lcVideo from '../../assets/lc.mp4';

type AppContainerTypes = {} & typeof defaultProps;
const defaultProps = {};

const AppContainer = (props: AppContainerTypes) => {
  const [emailInput, setEmailInput] = useState('');
  const [introLoaded, setIntroLoaded] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [showIntro, setShowIntro] = useState(true);
  const [toggleEducationPopup, setToggleEducationPopup] = useState(false);
  const [toggleAcademyList, setToggleAcademyList] = useState(0);

  const [canSkip, setCanSkip] = useState(false);


  const onChangeEmailInput = (e: any) => {
    const email = e.target.value;
    if (email.length > 50) return;
    setEmailInput(email);
  };

  const onPostEmail = () => new Promise((resolve) => {
    if (emailInput.length < 3) {
      toast.error('Please provide an email.');
      return;
    }

    const submissionData = {
      artistNameInput: 'N/A',
      emailInput: 'ELVIS|' + emailInput,
      socialInput: 'N/A',
      agreeToTermsAndConditions: 'N/A',
      contentType: 'EMAIL_INPUT',
      originalFileName: 'N/A',
      recaptchaToken: 'N/A',
    };

    Axios.post(
      'https://4b1dpmmxnc.execute-api.ap-southeast-2.amazonaws.com/dev/submission',
      submissionData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then(async (response: any) => {
      console.log(response);
      toast.success('Email received!');
      setEmailInput('');
    }).catch((error) => {
      toast.error('There was an error. Please try again.');
      resolve(error);
    });
  });

  const playIntro = () => {
    setIntroLoaded(false);
    const introVideo = document.getElementById('intro-video-lc');
    introVideo.currentTime = 0;
    introVideo.playbackRate = 1.2;
    introVideo.play();
    setCanSkip(true);
    const introTimer = setInterval(() => {
      if (introVideo.currentTime >= 27) {
        clearInterval(introTimer);
        setIntroLoaded(true);
        introVideo.pause();
      }
    }, 500);
  };

  useEffect(() => {
    if ((window.location.href).includes('vault')) {
      window.location.href = 'https://elvis.legacycouncil.io/';
    }

    if ((window.location.pathname).includes('learn')) {
      setToggleEducationPopup(true);
    }

    const path = window.location.href;
    if (path.includes('joinus')
    || path.includes('join-us')
    || path.includes('signup')
    || path.includes('utility')
    || path.includes('music')
    || path.includes('wearables')
    || path.includes('performances')
    || path.includes('metaverse')
    || path.includes('memorabilia')
  ) {
      setCanSkip(false);
      setShowSpinner(false);
      setShowIntro(false);
      setIntroLoaded(true);
    } else {
      const introVideo = document.getElementById('intro-video-lc');
      introVideo.pause();
      setTimeout(() => {
        setCanSkip(false);
        setShowSpinner(false);
        playIntro();
      }, 2000);
    }
  }, []);

  const proceedToWebsite = () => {
    if (!canSkip) return;
    setIntroLoaded(true);
    const introVideo = document.getElementById('intro-video-lc');
    introVideo.pause();
    setTimeout(() => {
      introVideo.currentTime = 0;
      setShowIntro(false);
    }, 1000);
  };

  const toggleEducationPopupActive = () => {
    setToggleEducationPopup(!toggleEducationPopup);
  };

  const toggleList = (listId) => {
    if (toggleAcademyList === listId) {
      setToggleAcademyList(0);
      return;
    }
    setToggleAcademyList(listId);
  };

  return (
    <Router>
      <>
        <ToastContainer
          position="top-left"
        />

        {toggleEducationPopup ? (
          <div className="education-popup">
            <div className="education-popup-container">
              <div
                className="close-education-popup"
                onClick={() => toggleEducationPopupActive()}
              />
              <h2>Elvis Academy</h2>
              <span>
                <i>"Truth is like the sun. You can shut it out for a time, but it ain't going away"</i> — Elvis Presley
                <br />
                <br />
                Learn about the brave new world of non-fungible-tokens NFTs with bitesize tutorials you can access in your own time.
                <br />
                <br />
                Through crypto education platform Collective Shift, we're offering 500 Starter memberships giving you access to a suite of tutorials, tools and definitions,
                just enter code <b>ELVISACADEMY</b> when prompted.
              </span>
              <ul>
                <li onClick={() => toggleList(1)}>
                  Introduction to Non-Fungible-Tokens (NFTs)
                  <div className={`expand-learn ${toggleAcademyList === 1 ? 'expand-active' : ''}`} />
                </li>
                {toggleAcademyList === 1 ? (
                  <ul>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://collectiveshift.io/nft/non-fungible-token-explainer/" target="_blank" rel="noreferrer"> Non fungible token explainer <div className="cta-learn">OPEN</div></a>
                    </li>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://collectiveshift.io/nft/marketplace/how-to-buy-nfts-on-nft-marketplaces" target="_blank" rel="noreferrer"> How to buy nfts on nft marketplaces <div className="cta-learn">OPEN</div></a>
                    </li>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://collectiveshift.io/nft/?slug=nft-outlook-and-challenges-for-investors-to-consider-greg-oakford-nft-fest-australia" target="_blank" rel="noreferrer"> NFT outlook and challenges for investors to consider <div className="cta-learn">OPEN</div></a>
                    </li>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://collectiveshift.io/nft/?slug=top-5-nft-questions-to-ask-before-you-invest-interview-greg-oakford" target="_blank" rel="noreferrer"> Top 5 nft questions to ask before you invest <div className="cta-learn">OPEN</div></a>
                    </li>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://collectiveshift.io/airdrop/airdrop-guide/" target="_blank" rel="noreferrer"> Airdrop guide <div className="cta-learn">OPEN</div></a>
                    </li>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://collectiveshift.io/video/nft-buying-strategy-floor-sweeping/" target="_blank" rel="noreferrer"> NFT buying strategy floor sweeping <div className="cta-learn">OPEN</div></a>
                    </li>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://collectiveshift.io/web3/what-is-web3/" target="_blank" rel="noreferrer"> What is Web3? <div className="cta-learn">OPEN</div></a>
                    </li>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://collectiveshift.io/tools/using-discord-to-your-advantage/" target="_blank" rel="noreferrer"> Using Discord to your Advantage <div className="cta-learn">OPEN</div></a>
                    </li>
                  </ul>
                ) : <></>}

                <li onClick={() => toggleList(2)}>
                  Buying &amp; Storing Crypto
                  <div className={`expand-learn ${toggleAcademyList === 2 ? 'expand-active' : ''}`} />
                </li>
                {toggleAcademyList === 2 ? (
                  <ul>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://collectiveshift.io/storage/ultimate-guide-to-storing-your-cryptocurrency/" target="_blank" rel="noreferrer"> Ultimate guide to storing cryptocurrency <div className="cta-learn">OPEN</div></a>
                    </li>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://collectiveshift.io/metamask/how-to-use-metamask/" target="_blank" rel="noreferrer"> How to use metamask <div className="cta-learn">OPEN</div></a>
                    </li>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://collectiveshift.io/exchange/practical-how-to-buy-bitcoin/" target="_blank" rel="noreferrer"> Practical how to buy bitcoin <div className="cta-learn">OPEN</div></a>
                    </li>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://collectiveshift.io/ethereum/what-is-gas-ethereum/" target="_blank" rel="noreferrer"> What is ethereum gas? <div className="cta-learn">OPEN</div></a>
                    </li>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://collectiveshift.io/tools/source-information-cryptocurrency-research/" target="_blank" rel="noreferrer"> Cryptocurrency research info <div className="cta-learn">OPEN</div></a>
                    </li>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://collectiveshift.io/crypto-commandments/" target="_blank" rel="noreferrer"> Crypto Commandments <div className="cta-learn">OPEN</div></a>
                    </li>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://collectiveshift.io/glossary/" target="_blank" rel="noreferrer"> Crypto Glossary <div className="cta-learn">OPEN</div></a>
                    </li>
                  </ul>
                ) : <></>}

                <li onClick={() => toggleList(3)}>
                  First Time in a Metaverse
                  <div className={`expand-learn ${toggleAcademyList === 3 ? 'expand-active' : ''}`} />
                </li>
                {toggleAcademyList === 3 ? (
                  <ul>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://collectiveshift.io/metaverse/my-take-on-decentraland-vs-the-sandbox/" target="_blank" rel="noreferrer"> My take on decentraland vs the sandbox <div className="cta-learn">OPEN</div></a>
                    </li>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://collectiveshift.io/metaverse/metaverse-office-hours-3d-virtual-world/" target="_blank" rel="noreferrer"> Metaverse office hours 3d virtual world <div className="cta-learn">OPEN</div></a>
                    </li>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://collectiveshift.io/metaverse/metaverse-round-up-and-heat-check/" target="_blank" rel="noreferrer"> Metaverse round up and heat check <div className="cta-learn">OPEN</div></a>
                    </li>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://collectiveshift.io/spotlight_report/decentraland/" target="_blank" rel="noreferrer"> Decentraland spotlight report <div className="cta-learn">OPEN</div></a>
                    </li>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://www.youtube.com/watch?v=fhn7yUUTRls&ab_channel=NewLegends" target="_blank" rel="noreferrer"> Logging into Decentraland as a guest <div className="cta-learn">OPEN</div></a>
                    </li>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://www.youtube.com/watch?v=03gIufXpF1k&ab_channel=Moto" target="_blank" rel="noreferrer"> Logging into Decentraland with metamask <div className="cta-learn">OPEN</div></a>
                    </li>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://www.youtube.com/watch?v=DVo9NsLJnRI&ab_channel=RealmPath" target="_blank" rel="noreferrer"> Decentraland beginners guide <div className="cta-learn">OPEN</div></a>
                    </li>
                  </ul>
                ) : <></>}

                <li onClick={() => toggleList(4)}>
                  Emerging Investor
                  <div className={`expand-learn ${toggleAcademyList === 4 ? 'expand-active' : ''}`} />
                </li>
                {toggleAcademyList === 4 ? (
                  <ul>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://collectiveshift.io/tools/nft-flips-finance-supply-listed/" target="_blank" rel="noreferrer"> NFT flips finance supply listed <div className="cta-learn">OPEN</div></a>
                    </li>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://collectiveshift.io/tools/guide-to-etherscan-for-analysis/" target="_blank" rel="noreferrer"> Guide to etherscan for analysis <div className="cta-learn">OPEN</div></a>
                    </li>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://collectiveshift.io/defi/lending-borrowing/earn-interest-on-ethereum-defi-lending-platforms/" target="_blank" rel="noreferrer"> Earn interest on ethereum lending platforms <div className="cta-learn">OPEN</div></a>
                    </li>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://collectiveshift.io/nft/?slug=nfts-intellectual-property-ip-rights" target="_blank" rel="noreferrer"> NFTs &amp; Intellectual Property (IP) Rights <div className="cta-learn">OPEN</div></a>
                    </li>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://collectiveshift.io/ethereum/ethereum-merge-busting-3-myths/" target="_blank" rel="noreferrer"> Ethereum merge busting 3 myths <div className="cta-learn">OPEN</div></a>
                    </li>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://collectiveshift.io/youtube/ethereum-merge-2022-incoming-what-you-must-know/" target="_blank" rel="noreferrer"> Ethereum merge 2022 (what you must know) <div className="cta-learn">OPEN</div></a>
                    </li>
                    <li style={{ backgroundColor: 'unset', color: '#000' }}>
                      <a href="https://collectiveshift.io/ethereum/?slug=a-deep-dive-into-ethereum-a-united-investment-perspective" target="_blank" rel="noreferrer"> A deep dive into ethereum <div className="cta-learn">OPEN</div></a>
                    </li>
                  </ul>
                ) : <></>}
              </ul>
              <span style={{ maxWidth: '100%', width: '100%', textAlign: 'center', marginTop: 5 }}>
                This is not financial advice.
                {' '}
                <a href="https://elvis-main-site.s3.ap-southeast-2.amazonaws.com/ElvisAcademy_TCs.pdf" target="_blank" rel="noreferrer">Click here for T&amp;Cs</a>
              </span>
            </div>
          </div>
        ) : <></>}


        {showIntro ? (
          <>
            <div
              className="intro-overlay"
              style={introLoaded ? { opacity: 0, top: -800, pointerEvents: 'none' } : { opacity: 1, top: 0 }}
              onClick={() => proceedToWebsite()}
            >
              <div className="intro-content" />
              <div className={`enter-experience-cta ${showSpinner ? '' : 'show-enter-experience-cta'}`}>
                EXPLORE
              </div>
              <div className="intro-video-lc">
                <div dangerouslySetInnerHTML={{
                  __html: `
                    <video
                      muted
                      playsinline
                      id="intro-video-lc"
                      preload="yes"
                      autoplay
                    >
                      <source src=${lcVideo} type="video/mp4" />
                    </video>
                  `,
                }}
                />
              </div>
              {showSpinner ? (
                <div
                  className="loading-spinner"
                  style={introLoaded ? { opacity: 0 } : { opacity: 1 }}
                />
              ) : <></>}
            </div>
            <div className="mobile-rotate-screen">
              Rotate Screen
              <div className="rotate-icon" />
            </div>
            <div className="legal-footer">
              ELVIS&trade; and ELVIS PRESLEY&trade; are trademarks of ABG EPE IP LLC
              Rights of Publicity and Persona Rights: Elvis Presley Enterprises, LLC
              &#169; 2022 ABG EPE IP LLC
            </div>
          </>
        ) : <></>}
        {introLoaded ? (
          <VideoComponent video={teaserVideo} introLoaded={introLoaded} />
        ) : <></>}
      </>
    </Router>
  );
};

AppContainer.defaultProps = defaultProps;

export default AppContainer;
