/* eslint-disable react/self-closing-comp */
/* eslint-disable import/no-duplicates */
/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable no-sparse-arrays */
/* eslint-disable comma-dangle */
/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable react/no-danger */
// @ts-nocheck

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from 'axios';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './video-component.scss';

import HeaderShow from './HeaderShow';
import musicVideo from '../../assets/music_1.mp4';
import blingVideo from '../../assets/bling_1.mp4';
import dinerVideo from '../../assets/diner_1.mp4';
import vaultVideo from '../../assets/vault_1.mp4';
import clothesVideo from '../../assets/clothes_1.mp4';
import tvVideo from '../../assets/tv_1.mp4';

import decentralandVideo from '../../assets/coins/DCL_A01.mp4';
import sandboxVideo from '../../assets/coins/Sandbox_A01.mp4';
import metakeyVideo from '../../assets/coins/Metakey_A01.mp4';

import pellarVideo from '../../assets/coins/Pellar_A01.mp4';
import riwVideo from '../../assets/coins/RIW_A01.mp4';
import elvisVideo from '../../assets/coins/Elvis_A02.mp4';
import abgVideo from '../../assets/coins/ABG_A01.mp4';
import coorestVideo from '../../assets/coins/Coorest_C01.mp4';
import voxelVideo from '../../assets/coins/Voxel_Architects_C01.mp4';
import vegasVideo from '../../assets/coins/Vegas_City_C01.mp4';
import monaVideo from '../../assets/coins/Mona_C01.mp4';
import dappcraftVideo from '../../assets/coins/Dappcraft_C01.mp4';
import spatialVideo from '../../assets/coins/Spatial_C01.mp4';
import nfttechVideo from '../../assets/coins/nfttech.mp4';

let prevScroll: any;
let currentPos: any;
currentPos = 0;

let videoRef: any;
let loopTimer: any;
let speedTimer: any;

let videoRefTeam1: any;
let videoRefTeam2: any;
let videoRefTeam3: any;

let videoRefSandbox: any;
let videoRefMetakey: any;
let videoRefDecentraland: any;
let videoRefCoorest: any;
let videoRefDappcraft: any;
let videoRefMona: any;
let videoRefVoxel: any;
let videoRefVegas: any;

type VideoComponentType = {
  video?: any,
  introLoaded: any
} & typeof defaultProps;
const defaultProps = {
  video: '',
};

let videoPlayTimer: any;
let videoPlayTimerSpeed: any;

const VideoComponent = (props: VideoComponentType) => {
  const navigate = useNavigate();
  const { video, introLoaded } = props;
  const [videoHide, setVideoHide] = useState(false);
  const [currentVideoSection, setCurrentVideoSection] = useState(0);
  const [pngLoopId, setPngLoopId] = useState(0);
  const [pngLoopFrameId, setPngLoopFrameId] = useState(0);
  const [mouseDistanceTop, setMouseDistanceTop] = useState(0);
  const [showEndCopy, setShowEndCopy] = useState(false);
  const [assetsLoaded, setAssetsLoaded] = useState(true);
  const [emailInput, setEmailInput] = useState('');
  const [emailInputValid, setEmailInputValid] = useState(false);
  const [tickBoxOneActive, setTickBoxOneActive] = useState(true);
  const [tickBoxTwoActive, setTickBoxTwoActive] = useState(true);
  const [enableEmail, setEnableEmail] = useState(true);
  const [currentVideoTime, setCurrentVideoTime] = useState(0);
  const [showUtility, setShowUtility] = useState(false);
  const [selectedActiveIndex, setSelectedActiveIndex] = useState(2);

  const setIndex = (i) => {
    setSelectedActiveIndex(i);
  };

  useEffect(() => {

  }, []);

  const handleDragStart = (e) => e.preventDefault();

  const onSlideChanged = (e) => {
    const { item } = e;
    if (!item) return;
    setIndex(item);
  };

  const toggleVideoSection = (videoSectionId: number) => {
    currentPos = videoSectionId;
    if (videoSectionId < 7) {
      window.scrollTo(0, 0);
    }
    setCurrentVideoSection(videoSectionId);

    if (videoSectionId === 0 && window.location.pathname.length > 2) navigate('/');
    if (videoSectionId === 7 && !window.location.pathname.includes('utility')) navigate('/utility');
    else if (videoSectionId === 6 && !window.location.pathname.includes('metaverse')) navigate('/metaverse');
    else if (videoSectionId === 5 && !window.location.pathname.includes('memorabilia')) navigate('/memorabilia');
    else if (videoSectionId === 4 && !window.location.pathname.includes('performances')) navigate('/performances');
    else if (videoSectionId === 3 && !window.location.pathname.includes('wearables')) navigate('/wearables');
    else if (videoSectionId === 2 && !window.location.pathname.includes('music')) navigate('/music');
    else if (videoSectionId === 10 && (!window.location.pathname.includes('joinus') && !window.location.pathname.includes('join-us') && !window.location.pathname.includes('signup'))) navigate('/join-us');
  };

  const onClickIcon = (urlPath: string) => {
    window.open(urlPath, '_blank', 'noopener');
  };

  const onClickLitePaper = () => {
    window.open('https://elvis-main-site.s3.ap-southeast-2.amazonaws.com/ElvisPreselyLegacy+Council_NFT_LitePaper.pdf', '_blank', 'noopener');
  };

  const onClickFAQs = () => {
    window.open('https://elvis-main-site.s3.ap-southeast-2.amazonaws.com/ElvisGenesisKeysFAQs.pdf', '_blank', 'noopener');
  };

  const onClickTwitter = () => {
    window.open('https://twitter.com/ElvisPresleyLC', '_blank', 'noopener');
  };

  const onClickDiscord = () => {
    window.open('https://discord.gg/ElvisPresleyLC', '_blank', 'noopener');
  };

  const onClickLinktree = () => {
    window.open('https://linktr.ee/ElvisPresleyLC', '_blank', 'noopener');
  };

  const onClickDecentraland = () => {
    window.open('https://decentraland.org/', '_blank', 'noopener');
  };

  const onClickSandbox = () => {
    window.open('https://www.sandbox.game', '_blank', 'noopener');
  };

  const onClickMetakey = () => {
    window.open('https://themetakey.com/', '_blank', 'noopener');
  };

  const startVideoLoop = (start: number, end: number) => {
    if (!videoRef) return;
    clearInterval(loopTimer);
    videoRef.currentTime = start;
    videoRef.play();
    loopTimer = setInterval(() => {
      const { currentTime } = videoRef;
      if (currentTime >= end) {
        videoRef.currentTime = start;
      }
    }, 10);
  };

  const playSection = () => {
    if (!videoRef) return;
    videoRef.pause();

    clearTimeout(speedTimer);
    clearInterval(loopTimer);
    clearTimeout(videoPlayTimer);
    clearTimeout(videoPlayTimerSpeed);

    videoRef.playbackRate = 1;

    // MINT
    if (currentVideoSection === 0) {
      videoRef.currentTime = 0;
      videoRef.pause();
    }

    // ENTER VAULT
    if (currentVideoSection === 1) {
      videoRef.currentTime = 0;
      videoRef.playbackRate = 1.5;
      videoRef.play();
      loopTimer = setInterval(() => {
        const { currentTime } = videoRef;
        if (currentTime >= 22.4) {
          videoRef.pause();
          startVideoLoop(22.3, 25.6);
        }
      }, 10);

      speedTimer = setTimeout(() => {
        videoRef.playbackRate = 1;
      }, 8000);
    }

    // MUSIC
    if (currentVideoSection === 2) {
      videoRef.currentTime = 19.5;
      videoRef.playbackRate = 1;
      videoRef.play();
      loopTimer = setInterval(() => {
        const { currentTime } = videoRef;
        if (currentTime >= 22.4) {
          videoRef.pause();
          startVideoLoop(22.4, 25.6);
        }
      }, 10);
    }

    // WEARABLES
    if (currentVideoSection === 3) {
      videoRef.currentTime = 27;
      videoRef.playbackRate = 1;
      videoRef.play();
      loopTimer = setInterval(() => {
        const { currentTime } = videoRef;
        if (currentTime >= 30.3) {
          videoRef.pause();
          startVideoLoop(30.4, 33.57);
        }
      }, 10);
    }

    // PERFORMANCES
    if (currentVideoSection === 4) {
      videoRef.currentTime = 35;
      videoRef.playbackRate = 1;
      videoRef.play();
      loopTimer = setInterval(() => {
        const { currentTime } = videoRef;
        if (currentTime >= 38.4) {
          videoRef.pause();
          startVideoLoop(38.5, 41.7);
        }
      }, 10);
    }

    // MEMORABILIA
    if (currentVideoSection === 5) {
      videoRef.currentTime = 43;
      videoRef.playbackRate = 1;
      videoRef.play();
      loopTimer = setInterval(() => {
        const { currentTime } = videoRef;
        if (currentTime >= 46.6) {
          videoRef.pause();
          startVideoLoop(46.7, 49.95);
        }
      }, 10);
    }

    // METAVERSE
    if (currentVideoSection === 6) {
      videoRef.currentTime = 51;
      videoRef.playbackRate = 1;
      videoRef.play();
      loopTimer = setInterval(() => {
        const { currentTime } = videoRef;
        if (currentTime >= 62) {
          videoRef.pause();
          clearInterval(loopTimer);
        }
      }, 10);
    }

    if (currentVideoSection > 6) {
      videoRef.currentTime = 0;
      videoRef.pause();
      setTimeout(() => {
        setShowUtility(true);
      }, 200);
    } else {
      setShowUtility(false);
    }

    if (currentVideoSection === 8) {
      videoRefDecentraland = document.getElementById('videoRefDecentraland');
      videoRefDecentraland.playbackRate = 1.1;

      videoRefMetakey = document.getElementById('videoRefMetakey');
      videoRefMetakey.playbackRate = 0.8;

      videoRefSandbox = document.getElementById('videoRefSandbox');
      videoRefSandbox.playbackRate = 1;

      videoRefCoorest = document.getElementById('videoRefCoorest');
      videoRefCoorest.playbackRate = 1.2;

      videoRefDappcraft = document.getElementById('videoRefDappcraft');
      videoRefDappcraft.playbackRate = 1.2;

      videoRefMona = document.getElementById('videoRefMona');
      videoRefMona.playbackRate = 1.1;

      videoRefVoxel = document.getElementById('videoRefVoxel');
      videoRefVoxel.playbackRate = 0.8;
    }

    if (currentVideoSection === 9) {
      videoRefTeam1 = document.getElementById('videoRefTeam1');
      videoRefTeam1.playbackRate = 1.1;

      videoRefTeam2 = document.getElementById('videoRefTeam2');
      videoRefTeam2.playbackRate = 0.7;

      videoRefTeam3 = document.getElementById('videoRefTeam3');
      videoRefTeam3.playbackRate = 0.9;
    }
  };

  const handleScroll = (e: any) => {
    const posY = Math.floor(window.scrollY);

    let scrollDirectionDown = false;
    let scrollThreshold = posY <= 0;
    if (posY > prevScroll) {
      scrollDirectionDown = true;
      scrollThreshold = posY >= 200;
    }

    let tempCurrentPos = currentPos;
    if (tempCurrentPos < 7) {
      window.scrollTo(0, 0);
      return;
    }

    if (scrollThreshold && scrollDirectionDown) {
      // SCROLL DOWN
      window.scrollTo(0, 100);
      tempCurrentPos += 1;
    } else if (scrollThreshold && !scrollDirectionDown) {
      // SCROLL UP
      window.scrollTo(0, 100);
      tempCurrentPos -= 1;
    }
    prevScroll = posY;

    if (tempCurrentPos < 7 || tempCurrentPos > 10) return;
    toggleVideoSection(tempCurrentPos);
  };

  const updateEmailInput = (e: any) => {
    const input = e.target.value;
    setEmailInput(input);
    if (input.length > 5 && input.includes('@') && input.includes('.')) {
      setEmailInputValid(true);
    } else {
      setEmailInputValid(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('scroll', handleScroll);

    const path = window.location.href;
    if (path.includes('joinus') || path.includes('join-us') || path.includes('signup')) {
      setTimeout(() => {
        toggleVideoSection(10);
      }, 10);
    }

    if (path.includes('music')) {
      setTimeout(() => {
        toggleVideoSection(2);
      }, 10);
    }

    if (path.includes('wearables')) {
      setTimeout(() => {
        toggleVideoSection(3);
      }, 10);
    }

    if (path.includes('performances')) {
      setTimeout(() => {
        toggleVideoSection(4);
      }, 10);
    }

    if (path.includes('memorabilia')) {
      setTimeout(() => {
        toggleVideoSection(5);
      }, 10);
    }

    if (path.includes('metaverse')) {
      setTimeout(() => {
        toggleVideoSection(6);
      }, 10);
    }

    if (path.includes('utility')) {
      setTimeout(() => {
        toggleVideoSection(7);
      }, 10);
    }
  }, []);

  const tickboxOneToggle = () => {
    setTickBoxOneActive(!tickBoxOneActive);
  };

  const tickboxTwoToggle = () => {
    setTickBoxTwoActive(!tickBoxTwoActive);
  };

  const postData = () => new Promise((resolve) => {
    const submissionData = {
      emailInput: `ELVIS|${emailInput}`,
      agreeToTermsAndConditions: 'TRUE',
      recaptchaToken: 'N/A',
      contentType: 'N/A',
      artistNameInput: 'N/A',
      socialInput: 'N/A',
      originalFileName: 'N/A',
    };

    Axios.post(
      'https://4b1dpmmxnc.execute-api.ap-southeast-2.amazonaws.com/dev/submission',
      submissionData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then(async (response) => {
      toast.success('Thanks! Your email has been successfully registered.');
      resolve(true);
    }).catch((error) => {
      toast.error('There was an error processing your email registration. Please try again and/or contact us over Discord.');
      resolve(false);
    });
  });

  const submitEmail = async () => {
    if (!enableEmail) return;

    setEnableEmail(false);

    if (!emailInputValid) {
      toast.error('Please enter a valid email.');
      setEnableEmail(true);
      return;
    }

    if (!tickBoxOneActive || !tickBoxTwoActive) {
      toast.error('You must agree to receive marketing communications.');
      setEnableEmail(true);
      return;
    }

    const postResult = await postData();

    if (postResult) {
      setTimeout(() => {
        setEnableEmail(true);
      }, 3000);
    }
  };

  const startTimer = () => {
    const intervalTimer = setInterval(() => {
      if (!videoRef) return;
      const { currentTime } = videoRef;
      setCurrentVideoTime(currentTime);

      if (currentTime > 62) {
        toggleVideoSection(7);
      }
    }, 100);

    setTimeout(() => {
      setAssetsLoaded(false);
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    startMouseTrackUp();
  }, []);

  useEffect(() => {
    videoRef = document.getElementById('videoId');
    playSection();
  }, [currentVideoSection]);

  const startMouseTrackUp = () => {
    const upArrow = document.getElementById('arrow-tracker');
    if (!upArrow) return;

    document.addEventListener('mousemove', (e: any) => getMouse(e));

    setInterval(() => followMouse(), 50);

    const arrowPos = { x: 0, y: 0 };
    const mouse = { x: 0, y: 0 };

    const getMouse = (e: any) => {
      mouse.x = e.pageX;
      mouse.y = e.pageY;
      const d = parseInt(((mouse.y / window.innerHeight) * 100).toFixed(0));
      setMouseDistanceTop(d);
    };

    const followMouse = () => {
      if (!arrowPos) return;
      const distX = mouse.x - arrowPos.x;
      const distY = mouse.y - arrowPos.y;
      arrowPos.x += distX / 2;
      arrowPos.y += distY / 2;

      if (!upArrow) return;
      upArrow.style.left = `${arrowPos.x}px`;
      upArrow.style.top = `${arrowPos.y}px`;
    };
  };

  const arrowTriggerNav = () => {
    let c = currentVideoSection;
    if (mouseDistanceTop <= 10) {
      if (currentVideoSection !== 10) c += 1;
    } else if (mouseDistanceTop > 90) {
      if (currentVideoSection !== 2) c -= 1;
      if (currentVideoSection === 2) c = 0;
    }
    setCurrentVideoSection(c);
  };

  return (
    <div
      className={`main-container ${currentVideoSection > 0 ? 'enable-cursor' : ''}`}
      style={currentVideoSection >= 7 ? { pointerEvents: 'all', cursor: 'default' } : {}}
    >
      <div className={`social-icon social-linktree ${currentVideoSection > 5 ? 'mobile-social-bottom-linktree' : ''}`} onClick={() => onClickLinktree()} />
      <div className={`social-icon social-discord ${currentVideoSection > 5 ? 'mobile-social-bottom-discord' : ''}`} onClick={() => onClickDiscord()} />
      <div className={`social-icon social-twitter ${currentVideoSection > 5 ? 'mobile-social-bottom-twitter' : ''}`} onClick={() => onClickTwitter()} />
      {/* <div className={`social-icon faqs ${currentVideoSection > 5 ? 'mobile-social-bottom-fags' : ''}`} onClick={() => onClickFAQs()} /> */}
      <div className={`social-icon litepaper ${currentVideoSection > 6 ? 'bottom-litepaper' : ''}`} onClick={() => onClickLitePaper()}>
        FAQs &amp; LITEPAPER
      </div>

      <ul
        className={`navigation mobile-nav-list ${currentVideoSection > 0 ? 'show-nav' : ''}`}
        style={currentVideoSection >= 7 ? { cursor: 'pointer' } : {}}
      >
        <li className={currentVideoSection === 0 ? 'active' : ''} onClick={() => toggleVideoSection(0)}>MINT</li>
        <li className={currentVideoSection === 2 || (currentVideoTime > 19 && currentVideoTime < 26 && currentVideoSection < 6) ? 'active' : ''} onClick={() => toggleVideoSection(2)}>MUSIC</li>
        <li className={currentVideoSection === 3 || (currentVideoTime > 27 && currentVideoTime < 34 && currentVideoSection < 6) ? 'active' : ''} onClick={() => toggleVideoSection(3)}>WEARABLES</li>
        <li className={currentVideoSection === 4 || (currentVideoTime > 35 && currentVideoTime < 42 && currentVideoSection < 6) ? 'active' : ''} onClick={() => toggleVideoSection(4)}>PERFORMANCES</li>
        <li className={currentVideoSection === 5 || (currentVideoTime > 43 && currentVideoTime < 50 && currentVideoSection < 6) ? 'active' : ''} onClick={() => toggleVideoSection(5)}>MEMORABILIA</li>
        <li className={currentVideoSection === 6 || (currentVideoTime > 51 && currentVideoTime < 75 && currentVideoSection < 7) ? 'active' : ''} onClick={() => toggleVideoSection(6)}>METAVERSE</li>
        <li className={currentVideoSection === 7 ? 'active' : ''} onClick={() => toggleVideoSection(7)}>UTILITY</li>
        <li className={currentVideoSection === 8 ? 'active' : ''} onClick={() => toggleVideoSection(8)}>PARTNERS</li>
        <li className={currentVideoSection === 9 ? 'active' : ''} onClick={() => toggleVideoSection(9)}>TEAM</li>
        <li className={currentVideoSection === 10 ? 'active' : ''} onClick={() => toggleVideoSection(10)}>JOIN US</li>
      </ul>

      <ul
        className={`navigation lift-buttons ${currentVideoSection > 0 ? 'show-nav' : ''}`}
        style={{ cursor: 'pointer' }}
      >
        <div className="lift-title">
          FLOORS
        </div>

        <li className={currentVideoSection === 0 ? 'active' : ''} onClick={() => toggleVideoSection(0)}>
          M
          <div className="hover-button-info">MINT</div>
        </li>
        <li className={currentVideoSection === 2 || (currentVideoTime > 19 && currentVideoTime < 26 && currentVideoSection < 6) ? 'active' : ''} onClick={() => toggleVideoSection(2)}>
          1
          <div className="hover-button-info">MUSIC</div>
        </li>
        <li className={currentVideoSection === 3 || (currentVideoTime > 27 && currentVideoTime < 34 && currentVideoSection < 6) ? 'active' : ''} onClick={() => toggleVideoSection(3)}>
          2
          <div className="hover-button-info">WEARABLES</div>
        </li>
        <li className={currentVideoSection === 4 || (currentVideoTime > 35 && currentVideoTime < 42 && currentVideoSection < 6) ? 'active' : ''} onClick={() => toggleVideoSection(4)}>
          3
          <div className="hover-button-info">PERFORMANCES</div>
        </li>
        <li className={currentVideoSection === 5 || (currentVideoTime > 43 && currentVideoTime < 50 && currentVideoSection < 6) ? 'active' : ''} onClick={() => toggleVideoSection(5)}>
          4
          <div className="hover-button-info">MEMORABILIA</div>
        </li>
        <li className={currentVideoSection === 6 || (currentVideoTime > 51 && currentVideoTime < 75 && currentVideoSection < 7) ? 'active' : ''} onClick={() => toggleVideoSection(6)}>
          5
          <div className="hover-button-info">METAVERSE</div>
        </li>
      </ul>

      <ul
        className={`navigation footer-list ${currentVideoSection > 0 ? 'show-nav' : ''}`}
        style={currentVideoSection >= 7 ? { cursor: 'pointer' } : {}}
      >
        <li className={currentVideoSection === 7 ? 'active' : ''} onClick={() => toggleVideoSection(7)}>UTILITY</li>
        <li className={currentVideoSection === 8 ? 'active' : ''} onClick={() => toggleVideoSection(8)}>PARTNERS</li>
        <li className={currentVideoSection === 9 ? 'active' : ''} onClick={() => toggleVideoSection(9)}>TEAM</li>
        <li className={currentVideoSection === 10 ? 'active' : ''} onClick={() => toggleVideoSection(10)}>JOIN US</li>
      </ul>

      <div
        className={`click-area
          ${mouseDistanceTop >= 10 ? 'show-tracker' : ''}
          ${mouseDistanceTop < 90 ? 'show-tracker' : ''}
          ${currentVideoSection === 0 ? 'hide-arrow-trackers' : ''}
        `}
        onClick={() => arrowTriggerNav()}
      >
        <div
          id="arrow-tracker"
          className={`${mouseDistanceTop >= 90 && currentVideoSection !== 0 ? 'tracker-down' : ''} ${mouseDistanceTop < 10 && currentVideoSection !== 0 ? 'tracker-up' : ''} ${currentVideoSection === 0 ? 'key-tracker' : ''}`}
        >
          {mouseDistanceTop < 10 ? (
            <span>
              {currentVideoSection === 1 ? 'MUSIC' : <></>}
              {currentVideoSection === 2 ? 'WEARABLES' : <></>}
              {currentVideoSection === 3 ? 'PERFORMANCES' : <></>}
              {currentVideoSection === 4 ? 'MEMORABILIA' : <></>}
              {currentVideoSection === 5 ? 'METAVERSE' : <></>}
              {currentVideoSection === 6 ? 'UTILITY' : <></>}
              {currentVideoSection === 7 ? 'PARTNERS' : <></>}
              {currentVideoSection === 8 ? 'TEAM' : <></>}
              {currentVideoSection === 9 ? 'JOIN US' : <></>}
            </span>
          ) : <></>}
          {mouseDistanceTop >= 90 ? (
            <span>
              {currentVideoSection === 1 || currentVideoSection === 2 ? 'MINT' : <></>}
              {currentVideoSection === 3 ? 'MUSIC' : <></>}
              {currentVideoSection === 4 ? 'WEARABLES' : <></>}
              {currentVideoSection === 5 ? 'PERFORMANCES' : <></>}
              {currentVideoSection === 6 ? 'MEMORABILIA' : <></>}
              {currentVideoSection === 7 ? 'METAVERSE' : <></>}
              {currentVideoSection === 8 ? 'UTILITY' : <></>}
              {currentVideoSection === 9 ? 'PARTNERS' : <></>}
              {currentVideoSection === 10 ? 'TEAM' : <></>}
            </span>
          ) : <></>}
        </div>
      </div>

      {currentVideoSection === 0 ? (
        <>
          <div className={`section-overlay passes-section ${!assetsLoaded ? 'animate-landing' : ''}`}>
            <div className="pass-container nft" onClick={() => toggleVideoSection(1)}>
              <div className="header-cta" />
              {/* <video width="100%" height="100%" autoPlay muted playsInline>
                <source src={musicVideo} type="video/mp4" />
              </video> */}
              <div className="grid-item-image">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `
                    <video
                      loop
                      muted
                      playsinline
                      id="videoRefMona"
                      preload="yes"
                      autoplay
                    >
                      <source src=${musicVideo} type="video/mp4" />
                    </video>
                  `,
                  }}
                />
              </div>
            </div>
            <span className="header-sub-title">Limited to 1935 Founding Members</span>
            <span className="header-access-cta">Explore the Elvis Presley™ Digital Estate</span>
          </div>
        </>
      ) : <></>}

      {currentVideoTime > 19 && currentVideoTime < 26 ? (
        <HeaderShow
          copy="Discover unreleased recordings and explore the catalogue of Elvis' greatest albums and singles. Decide what could be eternalized on the blockchain as exclusive, digital collectibles."
        />
      ) : <></>}

      {currentVideoTime > 27 && currentVideoTime < 34 ? (
        <HeaderShow
          copy="Curate digital replications of one of the most iconic wardrobes in music history, and decide which of Elvis' most famous outfits become officially licenced, collectible digital garments to be worn in the metaverse. Exclusive to all Founding Members."
        />
      ) : <></>}

      {currentVideoTime > 35 && currentVideoTime < 42 ? (
        <HeaderShow
          copy="Founding Members decide and allocate project funds to purchase the rights to unreleased video recordings, and to facilitate private online screenings of rarely seen Elvis moments, or backstage tour footage."
        />
      ) : <></>}

      {currentVideoTime > 43 && currentVideoTime < 50 ? (
        <HeaderShow
          copy="Use your voting power as we source, procure and display physical Elvis memorabilia from private collections, to be managed by the council. These items will be loaned to museums, for current and future generations of fans to enjoy."
        />
      ) : <></>}

      {currentVideoTime > 51 && currentVideoTime < 60 ? (
        <HeaderShow
          copy="Step into the digital world and experience the immersive Elvis Metaverse with exclusive airdrops and events. Plus, receive unique officially licensed Elvis digital spaces to call your own."
        />
      ) : <></>}

      {currentVideoSection === 8 ? (
        <HeaderShow
          title="UTILITY"
        />
      ) : <></>}

      {currentVideoSection === 9 ? (
        <HeaderShow
          title="PARTNERS"
        />
      ) : <></>}

      {currentVideoSection === 10 ? (
        <HeaderShow
          title="TEAM"
        />
      ) : <></>}

      {currentVideoSection === 11 ? (
        <HeaderShow
          title="JOIN US"
        />
      ) : <></>}

      {currentVideoSection === 7 ? (
        <div className="section-overlay utility-section">
          <HeaderShow title="UTILITY" />

          <div className="carousel-container">
            <AliceCarousel
              onSlideChanged={(e) => onSlideChanged(e)}
              disableButtonsControls
              disableDotsControls
              mouseTracking
              infinite
              // paddingLeft={5}
              // paddingRight={12}
              autoPlay={window.innerWidth < 900}
              autoPlayInterval={3000}
              autoPlayStrategy="all"
              activeIndex={selectedActiveIndex}
              animationEasingFunction="ease"
              animationType="slide"
              animationDuration={500}
              items={[
                <div className="carousel-card card-2" onDragStart={() => handleDragStart()} onClick={() => setIndex(0)}>
                  <div className="card-content">
                    <div
                      className="video-item"
                      dangerouslySetInnerHTML={{
                        __html: `
                      <video
                        loop
                        muted
                        playsinline
                        id="videoOne"
                        preload="yes"
                        autoplay
                      >
                        <source src=${blingVideo} type="video/mp4" />
                      </video>
                    `,
                      }}
                    />
                  </div>
                  <b>TRUE GOVERNANCE</b>
                  <span>
                    Become a Founding Member and a key decision maker in the Elvis Presley Legacy Council.
                  </span>
                </div>,
                <div className="carousel-card card-3" onDragStart={() => handleDragStart()} onClick={() => setIndex(1)}>
                  <div className="card-content">
                    <div
                      className="video-item"
                      dangerouslySetInnerHTML={{
                        __html: `
                      <video
                        loop
                        muted
                        playsinline
                        id="videoOne"
                        preload="yes"
                        autoplay
                      >
                        <source src=${tvVideo} type="video/mp4" />
                      </video>
                    `,
                      }}
                    />
                  </div>
                  <b>
                    WITNESS THE UNSEEN
                    <br />
                    &amp;
                    UNHEARD
                  </b>
                  <span>
                    Experience exclusive, unreleased Elvis music and footage through a Founding Members only platform.
                  </span>
                </div>,
                <div className="carousel-card card-1" onDragStart={() => handleDragStart()} onClick={() => setIndex(2)}>
                  <div className="card-content">
                    <div
                      className="video-item"
                      dangerouslySetInnerHTML={{
                        __html: `
                      <video
                        loop
                        muted
                        playsinline
                        id="videoOne"
                        preload="yes"
                        autoplay
                      >
                        <source src=${musicVideo} type="video/mp4" />
                      </video>
                    `,
                      }}
                    />
                  </div>
                  <b>A LIFETIME OF ELVIS</b>
                  <span>
                    A one-time investment for a lifetime of Elvis. This is an everlasting, yet fully transferrable Official Elvis digital membership.
                  </span>
                </div>,
                <div className="carousel-card card-4" onDragStart={() => handleDragStart()} onClick={() => setIndex(3)}>
                  <div className="card-content">
                    <div
                      className="video-item"
                      dangerouslySetInnerHTML={{
                        __html: `
                      <video
                        loop
                        muted
                        playsinline
                        id="videoOne"
                        preload="yes"
                        autoplay
                      >
                        <source src=${dinerVideo} type="video/mp4" />
                      </video>
                    `,
                      }}
                    />
                  </div>
                  <b>
                    A METAVERSE FOR FUTURE GENERATIONS
                  </b>
                  <span>
                    Build and experience the future world of Elvis with fellow Founding Members of the Council.
                  </span>
                </div>,
                <div className="carousel-card card-5" onDragStart={() => handleDragStart()} onClick={() => setIndex(4)}>
                  <div className="card-content">
                    <div
                      className="video-item"
                      dangerouslySetInnerHTML={{
                        __html: `
                      <video
                        loop
                        muted
                        playsinline
                        id="videoOne"
                        preload="yes"
                        autoplay
                      >
                        <source src=${clothesVideo} type="video/mp4" />
                      </video>
                    `,
                      }}
                    />
                  </div>
                  <b>OFFICIALLY LICENSED COLLECTABLES</b>
                  <span>
                    Curate and redeem exclusive, officially licensed Elvis Presley collectables.
                  </span>
                </div>,
                <div className="carousel-card card-6" onDragStart={() => handleDragStart()} onClick={() => setIndex(5)}>
                  <div className="card-content">
                    <div
                      className="video-item"
                      dangerouslySetInnerHTML={{
                        __html: `
                      <video
                        loop
                        muted
                        playsinline
                        id="videoOne"
                        preload="yes"
                        autoplay
                      >
                        <source src=${vaultVideo} type="video/mp4" />
                      </video>
                    `,
                      }}
                    />
                  </div>
                  <b>MANAGE THE TREASURY</b>
                  <span>
                    Collectively govern the council wallet and everything it holds, including historic physical Elvis memorabilia and future exhibitions.
                  </span>
                </div>,
                <div className="carousel-card card-2" onDragStart={() => handleDragStart()} onClick={() => setIndex(6)}>
                  <div className="card-content">
                    <div
                      className="video-item"
                      dangerouslySetInnerHTML={{
                        __html: `
                      <video
                        loop
                        muted
                        playsinline
                        id="videoOne"
                        preload="yes"
                        autoplay
                      >
                        <source src=${blingVideo} type="video/mp4" />
                      </video>
                    `,
                      }}
                    />
                  </div>
                  <b>TRUE GOVERNANCE</b>
                  <span>
                    Become a Founding Member and a key decision maker in the Elvis Presley Legacy Council.
                  </span>
                </div>,
                <div className="carousel-card card-3" onDragStart={() => handleDragStart()} onClick={() => setIndex(7)}>
                  <div className="card-content">
                    <div
                      className="video-item"
                      dangerouslySetInnerHTML={{
                        __html: `
                      <video
                        loop
                        muted
                        playsinline
                        id="videoOne"
                        preload="yes"
                        autoplay
                      >
                        <source src=${tvVideo} type="video/mp4" />
                      </video>
                    `,
                      }}
                    />
                  </div>
                  <b>
                    WITNESS THE UNSEEN
                    <br />
                    &amp;
                    UNHEARD
                  </b>
                  <span>
                    Experience exclusive, unreleased Elvis music and footage through a Founding Members only platform.
                  </span>
                </div>,
                <div className="carousel-card card-1" onDragStart={() => handleDragStart()} onClick={() => setIndex(8)}>
                  <div className="card-content">
                    <div
                      className="video-item"
                      dangerouslySetInnerHTML={{
                        __html: `
                      <video
                        loop
                        muted
                        playsinline
                        id="videoOne"
                        preload="yes"
                        autoplay
                      >
                        <source src=${musicVideo} type="video/mp4" />
                      </video>
                    `,
                      }}
                    />
                  </div>
                  <b>A LIFETIME OF ELVIS</b>
                  <span>
                    A one-time investment for a lifetime of Elvis. This is an everlasting, yet fully transferrable Official Elvis digital membership.
                  </span>
                </div>,
                <div className="carousel-card card-4" onDragStart={() => handleDragStart()} onClick={() => setIndex(9)}>
                  <div className="card-content">
                    <div
                      className="video-item"
                      dangerouslySetInnerHTML={{
                        __html: `
                      <video
                        loop
                        muted
                        playsinline
                        id="videoOne"
                        preload="yes"
                        autoplay
                      >
                        <source src=${dinerVideo} type="video/mp4" />
                      </video>
                    `,
                      }}
                    />
                  </div>
                  <b>
                    A METAVERSE FOR FUTURE GENERATIONS
                  </b>
                  <span>
                    Build and experience the future world of Elvis with fellow Founding Members of the Council.
                  </span>
                </div>,
                <div className="carousel-card card-5" onDragStart={() => handleDragStart()} onClick={() => setIndex(10)}>
                  <div className="card-content">
                    <div
                      className="video-item"
                      dangerouslySetInnerHTML={{
                        __html: `
                      <video
                        loop
                        muted
                        playsinline
                        id="videoOne"
                        preload="yes"
                        autoplay
                      >
                        <source src=${clothesVideo} type="video/mp4" />
                      </video>
                    `,
                      }}
                    />
                  </div>
                  <b>OFFICIALLY LICENSED COLLECTABLES</b>
                  <span>
                    Curate and redeem exclusive, officially licensed Elvis Presley collectables.
                  </span>
                </div>,
                <div className="carousel-card card-6" onDragStart={() => handleDragStart()} onClick={() => setIndex(11)}>
                  <div className="card-content">
                    <div
                      className="video-item"
                      dangerouslySetInnerHTML={{
                        __html: `
                      <video
                        loop
                        muted
                        playsinline
                        id="videoOne"
                        preload="yes"
                        autoplay
                      >
                        <source src=${vaultVideo} type="video/mp4" />
                      </video>
                    `,
                      }}
                    />
                  </div>
                  <b>MANAGE THE TREASURY</b>
                  <span>
                    Collectively govern the council wallet and everything it holds, including historic physical Elvis memorabilia and future exhibitions.
                  </span>
                </div>
              ]}
            />
          </div>
        </div>
      ) : <></>}

      {currentVideoSection === 8 ? (
        <div className="section-overlay partners-section">
          <HeaderShow title="PARTNERS" />
          <div className="grid-container partners">
            <div className="grid-item" onClick={() => onClickDecentraland()}>
              <div className="grid-item-image">
                <div
                  className="video-item"
                  dangerouslySetInnerHTML={{
                    __html: `
                    <video
                      loop
                      muted
                      playsinline
                      id="videoRefDecentraland"
                      preload="yes"
                      autoplay
                    >
                      <source src=${decentralandVideo} type="video/mp4" />
                    </video>
                  `,
                  }}
                />
              </div>
              <span>
                Decentraland
              </span>
            </div>
            <div className="grid-item" onClick={() => onClickSandbox()}>
              <div className="grid-item-image">
                <div
                  className="video-item"
                  dangerouslySetInnerHTML={{
                    __html: `
                    <video
                      loop
                      muted
                      playsinline
                      id="videoRefSandbox"
                      preload="yes"
                      autoplay
                    >
                      <source src=${sandboxVideo} type="video/mp4" />
                    </video>
                  `,
                  }}
                />
              </div>
              <span>
                Sandbox
              </span>
            </div>
            <div className="grid-item" onClick={() => onClickMetakey()}>
              <div className="grid-item-image">
                <div
                  className="video-item"
                  dangerouslySetInnerHTML={{
                    __html: `
                    <video
                      loop
                      muted
                      playsinline
                      id="videoRefMetakey"
                      preload="yes"
                      autoplay
                    >
                      <source src=${metakeyVideo} type="video/mp4" />
                    </video>
                  `,
                  }}
                />
              </div>
              <span>
                Metakey
              </span>
            </div>
            <div className="grid-item" onClick={() => onClickIcon('https://www.mona.gallery/')}>
              <div className="grid-item-image">
                <div
                  className="video-item"
                  dangerouslySetInnerHTML={{
                    __html: `
                    <video
                      loop
                      muted
                      playsinline
                      id="videoRefMona"
                      preload="yes"
                      autoplay
                    >
                      <source src=${monaVideo} type="video/mp4" />
                    </video>
                  `,
                  }}
                />
              </div>
              <span>
                Mona
              </span>
            </div>
            <div className="grid-item" onClick={() => onClickIcon('https://spatial.io/')}>
              <div className="grid-item-image">
                <div
                  className="video-item"
                  dangerouslySetInnerHTML={{
                    __html: `
                    <video
                      loop
                      muted
                      playsinline
                      id="videoRefMona"
                      preload="yes"
                      autoplay
                    >
                      <source src=${spatialVideo} type="video/mp4" />
                    </video>
                  `,
                  }}
                />
              </div>
              <span>
                Spatial
              </span>
            </div>
            <div className="grid-item" onClick={() => onClickIcon('https://www.nfttech.com')}>
              <div className="grid-item-image">
                <div
                  className="video-item"
                  dangerouslySetInnerHTML={{
                    __html: `
                    <video
                      loop
                      muted
                      playsinline
                      id="videoRefDappcraft"
                      preload="yes"
                      autoplay
                    >
                      <source src=${nfttechVideo} type="video/mp4" />
                    </video>
                  `,
                  }}
                />
              </div>
              <span>
                NFT Tech
              </span>
            </div>
            <div className="grid-item" onClick={() => onClickIcon('https://dappcraft.io/')}>
              <div className="grid-item-image">
                <div
                  className="video-item"
                  dangerouslySetInnerHTML={{
                    __html: `
                    <video
                      loop
                      muted
                      playsinline
                      id="videoRefDappcraft"
                      preload="yes"
                      autoplay
                    >
                      <source src=${dappcraftVideo} type="video/mp4" />
                    </video>
                  `,
                  }}
                />
              </div>
              <span>
                Dappcraft
              </span>
            </div>
            <div className="grid-item" onClick={() => onClickIcon('https://coorest.io/')}>
              <div className="grid-item-image">
                <div
                  className="video-item"
                  dangerouslySetInnerHTML={{
                    __html: `
                    <video
                      loop
                      muted
                      playsinline
                      id="videoRefCoorest"
                      preload="yes"
                      autoplay
                    >
                      <source src=${coorestVideo} type="video/mp4" />
                    </video>
                  `,
                  }}
                />
              </div>
              <span>
                Coorest
              </span>
            </div>
            <div className="grid-item" onClick={() => onClickIcon('https://voxelarchitects.com/')}>
              <div className="grid-item-image">
                <div
                  className="video-item"
                  dangerouslySetInnerHTML={{
                    __html: `
                    <video
                      loop
                      muted
                      playsinline
                      id="videoRefVoxel"
                      preload="yes"
                      autoplay
                    >
                      <source src=${voxelVideo} type="video/mp4" />
                    </video>
                  `,
                  }}
                />
              </div>
              <span>
                Voxel Architects
              </span>
            </div>
            <div className="grid-item" onClick={() => onClickIcon('https://www.vegascity.org/about')}>
              <div className="grid-item-image">
                <div
                  className="video-item"
                  dangerouslySetInnerHTML={{
                    __html: `
                    <video
                      loop
                      muted
                      playsinline
                      id="videoRefVegas"
                      preload="yes"
                      autoplay
                    >
                      <source src=${vegasVideo} type="video/mp4" />
                    </video>
                  `,
                  }}
                />
              </div>
              <span>
                Vegas City
              </span>
            </div>
          </div>
        </div>
      ) : <></>}

      {currentVideoSection === 9 ? (
        <div className="section-overlay team-section">
          <HeaderShow title="TEAM" />
          <div className="grid-container team">
            <div className="grid-item" onClick={() => onClickIcon('https://www.graceland.com/')}>
              <div className="grid-item-image">
                <div
                  className="video-item"
                  dangerouslySetInnerHTML={{
                    __html: `
                    <video
                      loop
                      muted
                      playsinline
                      id="videoRefTeam1"
                      preload="yes"
                      autoplay
                    >
                      <source src=${elvisVideo} type="video/mp4" />
                    </video>
                  `,
                  }}
                />
              </div>
              <span>
                Elvis Presley Enterprises
              </span>
            </div>
            <div className="grid-item" onClick={() => onClickIcon('https://www.authenticbrands.com/')}>
              <div className="grid-item-image">
                <div
                  className="video-item"
                  dangerouslySetInnerHTML={{
                    __html: `
                    <video
                      loop
                      muted
                      playsinline
                      id="videoRefTeam2"
                      preload="yes"
                      autoplay
                    >
                      <source src=${abgVideo} type="video/mp4" />
                    </video>
                  `,
                  }}
                />
              </div>
              <span>
                ABG
              </span>
            </div>
            <div className="grid-item" onClick={() => onClickIcon('https://runitwild.io/')}>
              <div className="grid-item-image">
                <div
                  className="video-item"
                  dangerouslySetInnerHTML={{
                    __html: `
                    <video
                      loop
                      muted
                      playsinline
                      id="videoRefTeam3"
                      preload="yes"
                      autoplay
                    >
                      <source src=${riwVideo} type="video/mp4" />
                    </video>
                  `,
                  }}
                />
              </div>
              <span>
                Run It Wild
              </span>
            </div>
            <div className="grid-item" onClick={() => onClickIcon('https://pellar.io/')}>
              <div className="grid-item-image">
                <div
                  className="video-item"
                  dangerouslySetInnerHTML={{
                    __html: `
                    <video
                      loop
                      muted
                      playsinline
                      id="videoOne"
                      preload="yes"
                      autoplay
                    >
                      <source src=${pellarVideo} type="video/mp4" />
                    </video>
                  `,
                  }}
                />
              </div>
              <span>
                Pellar
              </span>
            </div>
          </div>
        </div>
      ) : <></>}

      {currentVideoSection === 10 ? (
        <div className="section-overlay launch-section">
          <HeaderShow title="JOIN US" />
          <div className="confirm-email-comms">
            <div className="social-copy">
              Are you interested in becoming
              <br />
              a founding member? Join us.
              <br />
              <br />

            </div>
            <div className="tickbox-container" onClick={() => tickboxOneToggle()}>
              <div className={`tickbox ${tickBoxOneActive ? 'tickbox-active' : ''}`} />
              <span>Receive project related marketing</span>
            </div>
            <div className="tickbox-container" onClick={() => tickboxTwoToggle()}>
              <div className={`tickbox ${tickBoxTwoActive ? 'tickbox-active' : ''}`} />
              <span>Receive marketing from Run It Wild and partners</span>
            </div>
          </div>
          <div className="register-email">
            <input
              onChange={(e) => updateEmailInput(e)}
              value={emailInput}
              placeholder="Enter your email"
            />
            <div className="email-button" onClick={() => submitEmail()}>
              {enableEmail ? 'SEND' : 'LOADING'}
            </div>
          </div>
          <div className="discord-cta-container">
            <div className="discord-button-large" onClick={() => onClickDiscord()}>
              <div className="discord-icon" />
              DISCORD
            </div>
          </div>
        </div>
      ) : <></>}

      {/* {currentVideoSection === 0 ? (
        <div
          className="video-intro-component"
          // style={currentVideoSection === 0 ? { opacity: 0 } : { opacity: 1 }}
          dangerouslySetInnerHTML={{
            __html: `
          <video
            loop
            muted
            playsinline
            id="videoOne"
            preload="yes"
            autoplay
          >
            <source src=${video} type="video/mp4" />
          </video>
        `,
          }}
        />
      ) : <></>} */}

      <div
        className={`video-component ${videoHide ? 'hide-video' : ''} ${currentVideoSection > 0 ? 'reveal-video' : ''} ${assetsLoaded ? '' : 'pulse-video'} ${currentVideoTime > 60 ? 'metaverse-transition' : ''}`}
        // style={currentVideoSection === 0 ? { opacity: 0 } : { opacity: 1 }}
        dangerouslySetInnerHTML={{
          __html: `
        <video
          muted
          playsinline
          id="videoId"
          preload="yes"
        >
          <source src=${video} type="video/mp4" />
        </video>
      `,
        }}
      />
    </div>
  );
};

VideoComponent.defaultProps = defaultProps;

export default VideoComponent;
