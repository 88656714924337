import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { AppContainer } from './containers';
import reportWebVitals from './reportWebVitals';
import { StateProvider } from './store';

ReactDOM.render(
  <React.StrictMode>
    <StateProvider>
      <AppContainer />
    </StateProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
